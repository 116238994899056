import { useState } from 'react'
import ContactForm from '../contactForm/ContactForm';
import './Copyright.css';

function Copyright() {
    const [modal, setShowModal] = useState(false);
    const showModal = () => {
        setShowModal(true);
    }
    const closeModal = () => {
        setShowModal(false);
    }

    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    return (
        <>
        <section className={'copyright'}>© {currentYear} IDM VALLEY</section>
        <button className={'homeQuotes'} onClick={showModal}><img className={'homeQuotesImg'} src="/img/request.png" alt='web development' /></button>
        <a className={'homeWhatsapp'} rel="noreferrer" href="https://api.whatsapp.com/send?phone=919891054016&text=Hello" target={'_blank'}><img className={'homeWhatsappImg'} src="/img/whatsapp.png" alt="whatsapp"/></a>
        {modal ? 
        <div className={'modal copyrightModal'}>
            <div className={'modal-body'}>
                <button className='close-btn' onClick={closeModal}>&times;</button>
                <div className={'modal-content'}>
                    <ContactForm/>
                </div>
            </div>
        </div>
        : ''
        }
        </>
    );
  }
  
  export default Copyright;
  


