import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './components/homePage/HomePage';
import About from './components/aboutPage/AboutPage';
import Contact from './components/contactPage/ContactPage';
import Blogs from './components/blogsPage/BlogsPage';
import Portfolio from './components/portfolioPage/PortfolioPage';
import Services from './components/servicesPage/ServicesPage';
import ServicesDetail from './components/servicesDetailPage/ServicesDetailPage';
import BlogDetail from './components/blogDetailPage/BlogDetailPage';
import Terms from './components/termsPage/TermsPage';
import Privacy from './components/privacyPage/PrivacyPage';
import Faq from './components/faqPage/FaqPage';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route,} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/contact" element={<Contact />}></Route>
        <Route exact path="/blogs" element={<Blogs />}></Route>
        <Route exact path="/portfolio" element={<Portfolio />}></Route>
        <Route exact path="/services" element={<Services />}></Route>
        <Route exact path="/services/:slug" element={<ServicesDetail />}></Route>
        <Route exact path="/blogs/:slug" element={<BlogDetail />}></Route>
        <Route exact path="/terms-and-conditions" element={<Terms />}></Route>
        <Route exact path="/privacy-policy" element={<Privacy />}></Route>
        <Route exact path="/faqs" element={<Faq />}></Route>
        <Route path="*" element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
