import './Footer.css';
import { Link } from 'react-router-dom';
import appConstant from "../../shared/constant/constant.json";

function Footer() {
    return (
        <section className={'footer'}>
            <div className='footer-top'>
                <div className={'logo'}>
                    <Link to='/'>
                        {/* <img src="/img/logo.png" alt='logo' /> */}
                        <h1 className='heading'>IDMValley</h1>
                        <span>We Build What You Think</span>
                    </Link>
                </div>
                <div className='contact-info'>
                    <div className="icon-wraper">
                        <div className="icon-lg">
                            <img src='/img/mail.png' alt='icon'/>
                        </div>
                        <div className="icon-content">
                            <a className='content' href={`tel:+91 + ${appConstant.PHONE_NUMBER}`}>+91 {appConstant.PHONE_NUMBER}</a><br/>
                            <a className='content' href="mailto:hi@idmvalley.com">hi@idmvalley.com</a>
                        </div>
                    </div>
                    <div className="icon-wraper">
                        <div className="icon-lg">
                            <img src='/img/location.png' alt='icon'/>
                        </div>
                        <div className="icon-content">
                            <a href='https://goo.gl/maps/vmdGqJBp61epkk4Q6' rel="noreferrer" target="_blank" className='content'>A7/15 Ground Floor, Sec 85 BPTP, Faridabad, HR, 121002</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>
                <div className='footer-column'>
                    <h6 className='footer-heading'>About Us</h6>
                    <p className='bottom-content'>
                    At IDM Valley, we understand the ever-evolving digital landscape and the impact it has on businesses.
                    </p>
                    <div className={'socialLinks'}>
                        <a href="https://www.facebook.com/idmvalley/" className={'icons'} rel="noreferrer" target={'_blank'}><img className='facebook' src="/img/facebook.png" alt="facebook"/></a>
                        <a href="https://twitter.com/idmvalley" className={'icons'} rel="noreferrer" target={'_blank'}><img className='twitter' src="/img/twitter.png" alt="twitter"/></a>
                        <a href="https://www.instagram.com/idmvalley/" className={'icons'} rel="noreferrer" target={'_blank'}><img className='instagram' src="/img/instagram.png" alt="instagram"/></a>
                        <a href="https://www.linkedin.com/in/idm-valley-870628207/?originalSubdomain=in" className={'icons'} rel="noreferrer" target={'_blank'}><img src="/img/linkedin.png" className='linkedin' alt="linkedin"/></a>
                        <a href="https://api.whatsapp.com/send?phone=919891054016&text=Hello" rel="noreferrer" className={'icons'} target={'_blank'}><img src="/img/whatsapp1.png" className='whatsapp' alt="whatsapp"/></a>
                    </div>
                </div>
                <div className='footer-column'>
                    <h6 className='footer-heading'>Our Links</h6>
                    <ul className={'footer-list'}>
                        <Link className={'footerlinks bottom-content'} to='/'>Home</Link>
                        <Link className={'footerlinks bottom-content'} to='/about'>About Us</Link>
                        <Link className={'footerlinks bottom-content'} to='/services'>Services</Link>
                        <Link className={'footerlinks bottom-content'} to='/contact'>Contact</Link>
                    </ul>
                </div>
                <div className='footer-column'>
                    <h6 className='footer-heading'>Our Services</h6>
                    <ul className={'footer-list'}>
                        <Link className={'footerlinks bottom-content'} to='/services/mobile-applications'>Mobile Applications</Link>
                        <Link className={'footerlinks bottom-content'} to='/services/email-marketing'>Email Marketing</Link>
                        <Link className={'footerlinks bottom-content'} to='/services/digital-marketing'>Digital Marketing</Link>
                        <Link className={'footerlinks bottom-content'} to='/services/shopify-development'>Shopify Development</Link>
                    </ul>
                </div>
                <div className='footer-column'>
                    <h6 className='footer-heading'>Other Links</h6>
                    <ul className={'footer-list'}>
                        <Link className={'footerlinks bottom-content'} to='/faqs'>FAQ's</Link>
                        <Link className={'footerlinks bottom-content'} to='/portfolio'>Portfolio</Link>
                        <Link className={'footerlinks bottom-content'} to='/privacy-policy'>Privacy Policy</Link>
                        <Link className={'footerlinks bottom-content'} to='/terms-and-conditions'>Terms & Conditions</Link>
                    </ul>
                </div>
            </div>
        </section>
    );
  }
  
  export default Footer;
  


