import { Link } from "react-router-dom";

function ServiceCard(props) {
    return (
        <Link to={'/services/' + props.slug} className='services-columns'>
            <div className={'icon-box ' + props.bgColor}>
                <img src={'/service/' + props.img} alt='icon'/>
            </div>
            <h6 className='service-title'>{props.title}</h6>
            <p className='content'>{props.content}</p>
        </Link>
    );
  }

  export default ServiceCard;
