import './Contact.css';
import ContactForm from '../contactForm/ContactForm';

function Contact(props) {
    return (
        <div className='contact-section'>
            <div className='contact-columns1'>
              <div className='sub-title'>Contact Us</div>
                <h2 className="title">We Love To Help You To Enlarge Your Revenues.</h2>
                <ContactForm myService={props.service}/>
              </div>
            <div className='contact-columns2'>
              <div className='contact-img'>
                <img className='static-img' src='/img/contact-img/contact-img-1.png' alt='contact-img' />
                <img className='moving-img img-1' src='/img/contact-img/contact-img-2.png' alt='contact-img' />
                <img className='moving-img img-2' src='/img/contact-img/contact-img-3.png' alt='contact-img' />
                <img className='moving-img img-3' src='/img/contact-img/contact-img-4.png' alt='contact-img' />
                <img className='moving-img img-4' src='/img/contact-img/contact-img-5.png' alt='contact-img' />
                <img className='moving-img img-5' src='/img/contact-img/contact-img-6.png' alt='contact-img' />
                <img className='moving-img img-6' src='/img/contact-img/contact-img-7.png' alt='contact-img' />
                <img className='moving-img img-7' src='/img/contact-img/contact-img-8.png' alt='contact-img' />
                <img className='moving-img img-8' src='/img/contact-img/contact-img-9.png' alt='contact-img' />
              </div>
            </div>
        </div>
    );
  }

  export default Contact;
