import './Clients.css';

function Clients() {
    return (
        <section className={'clients'}>
            <div id="infinite" className={'highwaySlider'}>
                <div className={'highwayBarrier'}>
                    <ul className={'highwayLane'}>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/avshack.png' alt='avshack' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/DivineBamboo.png' alt='DivineBamboo' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/goldleaf.png' alt='goldleaf' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/janapril.png' alt='janapril' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/kaizen.png' alt='kaizen' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/kwc.png' alt='kwc' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/modv.png' alt='modv' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/pixelrush.png' alt='pixelrush' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/revital.png' alt='revital' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/riderzplanet.png' alt='riderzplanet' /></span></li> 

                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/avshack.png' alt='avshack' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/DivineBamboo.png' alt='DivineBamboo' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/goldleaf.png' alt='goldleaf' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/janapril.png' alt='janapril' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/kaizen.png' alt='kaizen' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/kwc.png' alt='kwc' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/modv.png' alt='modv' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/pixelrush.png' alt='pixelrush' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/revital.png' alt='revital' /></span></li>
                        <li className={'highwayCar'}><span className={'clientImage'}><img src='/clients/riderzplanet.png' alt='riderzplanet' /></span></li>                
                    </ul>
                </div>
            </div>
        </section>
    );
  }
  
  export default Clients;
  


