import React, {useEffect, useState} from 'react';
import '../blogsPage/BlogsPage.css';
import TopHeader from "../../shared/topHeader/TopHeader";
import Header from "../../shared/header/Header";
import Copyright from "../../shared/copyright/Copyright";
import Footer from "../../shared/footer/Footer";
import SinglePageBanner from "../../shared/singlePageBanner/SinglePageBanner";
import { useParams } from 'react-router-dom';
import axios from "axios";
import appConstant from "../../shared/constant/constant.json";
import Loader from '../../shared/loader/Loader';

function BlogDetailPage() {
    const [blogs, setBlogs] = React.useState([]);
    const [blog, setBlog] = useState({});
    const [isLoader, setIsLoader] = React.useState(true);
    const props = useParams();
    useEffect(() => {
      blogsData();
      getBlogData();
    }, []);

    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    const blogsData = () => {
      axios.get(`${appConstant.API_URL}/blogs`, params)
          .then(res => {
            setIsLoader(false);
            if (res.data.status === 200) {
              setBlogs(res.data.data);
            }
          })
          .catch(error => {
            setIsLoader(true);
          });
    }
    const getBlogData = () => {
        axios.get(`${appConstant.API_URL}/blog_details/${props.slug}`, params)
            .then(res => {  
                setIsLoader(false);              
                if(res?.data?.status === 200){
                    setBlog(res?.data?.data);
                }else{
                    setBlog({});
                }
            })
            .catch(error => {
                setIsLoader(true);
            });
    }
    return (
        <>
        <TopHeader/>
        <Header id='5' white='true'/>
        <SinglePageBanner page={blog.title} parentPage="Blogs" parentPageSlug="/blogs"/>
        <section className={'pages-content'}>
        <div className='blogsPage'>
            {isLoader ? (
                <Loader />
            ) : (
            <>
                <div className='blogsColumn1'>
                    <img src={`${appConstant.IMAGE_URL}/blogs/` + blog?.image} alt={blog?.title}/>
                    <h1 className='title'>{blog?.title}</h1>
                    <h6 className='dated'>Posted - {blog?.posted_on}</h6>
                    <div dangerouslySetInnerHTML={{__html: blog?.description}} className="content"></div>                   
                </div>
                <div className='blogsColumn2'>
                    <div className='recent-box'>
                        <h3 className='heading'>Recent Blogs</h3>
                        {blogs?.length > 0 && blogs?.map((result, i) =>
                             <a  key={'blogs' + i} href={'/blogs/' + result?.slug} className='recent-blogs'>
                             <div className='recent-column1'>
                                 <img className="blog-img" src={`${appConstant.IMAGE_URL}/blogs/` + result?.image} alt={result?.title}/>
                             </div>
                             <div className='recent-column2'>
                                 <h6 className='blog-title'>{result?.title}</h6>
                                 <div className='blog-content' dangerouslySetInnerHTML={{__html: result?.description}}></div>
                             </div>
                         </a>                                           
                        )}
                    </div>          
                </div>
                </>
            )}
           </div>
        </section>
        {/* footer */}
        <Footer/>
        {/* copyright */}
        <Copyright/>
        </>
    );
  }
  
  export default BlogDetailPage;