import { Link } from 'react-router-dom';
import './SinglePageBanner.css';

function SinglePageBanner(props) {
    return (
        <section className={'singlePageBanner'}>
            <h1 className={'page-heading'}>{props.page}</h1>
            <h3 className='breadcrumbs'><Link to='/'>Home &raquo;</Link> {props.parentPage ? <Link to={props.parentPageSlug}>{props.parentPage} &raquo;</Link> : ''} <span>{props.page}</span></h3>
        </section>
    );
  }
  
  export default SinglePageBanner;