import PortfolioCard from "../portfolioCard/PortfolioCard";
import "./Portfolio.css";
import React, { useEffect } from "react";
import axios from "axios";
import appConstant from "../../shared/constant/constant.json";
import Loader from "../loader/Loader";

function Portfolio() {
  const [menuActive, setMenuActive] = React.useState(0);
  const [category, setCategory] = React.useState([]);
  const [portfolio, setPortfolio] = React.useState([]);
  const [isLoader, setIsLoader] = React.useState(true);
  useEffect(() => {
    categoryData();
    portfolioData();
  }, []);
  const params = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const categoryData = () => {
    axios
      .get(`${appConstant.API_URL}/category`, params)
      .then((res) => {
        if (res.data.status === 200) {
          setCategory(res.data.data);
        }
      })
      .catch((error) => {});
  };
  const portfolioData = (id = 0) => {
    setMenuActive(id);
    axios
      .get(`${appConstant.API_URL}/portfolio/${id}`, params)
      .then((res) => {
        setIsLoader(false);
        if (res.data.status === 200) {
          setPortfolio(res.data.data);
        }
      })
      .catch((error) => {setIsLoader(true);});
  };
  return (
    <section className={"portfolio"}>
      <nav className={"navbar"}>
        <button onClick={() => portfolioData()} className={menuActive === 0 ? "navlinks active" : "navlinks"}>All</button>
        {category?.length > 0 &&
          category?.map((result, i) => (
            <button key={"category" + i} onClick={() => portfolioData(result?.id)} className={menuActive === result.id ? "navlinks active" : "navlinks"}>
              {result?.category}
            </button>
          ))}
      </nav>
      <div className={"galleryContainer"}>
        {isLoader ? (
            <Loader />
        ) : (
          <>
            {portfolio?.length > 0 &&
              portfolio?.map((result, i) => <PortfolioCard data={result} />)}
          </>
        )}
      </div>
    </section>
  );
}

export default Portfolio;
