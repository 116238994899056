import React, { useState, useEffect } from "react";
import "./ServicesDetailPage.css";
import TopHeader from "../../shared/topHeader/TopHeader";
import Header from "../../shared/header/Header";
import Copyright from "../../shared/copyright/Copyright";
import Footer from "../../shared/footer/Footer";
import SinglePageBanner from "../../shared/singlePageBanner/SinglePageBanner";
import Contact from "../../shared/contact/Contact";
import Clients from "../../shared/clients/Clients";
import { useParams } from "react-router-dom";
import axios from "axios";
import appConstant from "../../shared/constant/constant.json";
import MetaTags from "react-meta-tags";
import Loader from "../../shared/loader/Loader";

function ServicesDetailPage() {
  const props = useParams();
  const [isLoader, setIsLoader] = React.useState(true);
  const [services, setServices] = useState([]);
  const [serviceDetail, setServiceDetail] = useState([]);
  useEffect(() => {
    servicesData();
    serviceDetailsData();
  }, []);
  const params = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const servicesData = () => {
    axios
      .get(`${appConstant.API_URL}/services`, params)
      .then((res) => {
        setIsLoader(false);
        if (res.data.status === 200) {
          setServices(res.data.data);
        }
      })
      .catch((error) => {
        setIsLoader(true);
      });
  };

  const serviceDetailsData = () => {
    axios
      .get(`${appConstant.API_URL}/services/${props.slug}`, params)
      .then((res) => {
        setIsLoader(false);
        if (res.data.status === 200) {
          setServiceDetail(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((error) => {
        setIsLoader(true);
      });
  };
  return (
    <>
      <MetaTags>
        <title>{serviceDetail?.seo_title} | IDM Valley</title>
        <meta name="description" content={serviceDetail?.seo_description} />
        <meta name="keywords" content={serviceDetail?.seo_keywords} />
        <meta
          property="og:title"
          content={serviceDetail?.seo_title + " | IDM Valley"}
        />
        <meta
          property="og:image"
          content={
            serviceDetail?.image
              ? "/serviceDetail/" + serviceDetail?.image
              : "/serviceDetail/email-marketing.png"
          }
        />
      </MetaTags>
      <TopHeader />
      <Header id="3" white="true" />
      <SinglePageBanner
        page={serviceDetail?.title}
        parentPage="Services"
        parentPageSlug="/services"
      />
      <section className={"pages-content"}>
        <div className="serviceDetailPage">
          {isLoader ? (
            <Loader />
          ) : (
            <>
              <div className="servicesDetailColumn1">
                <h1 className="title">{serviceDetail?.title}</h1>
                <img
                  className="serviceImg"
                  src={
                    serviceDetail?.image
                      ? "/serviceDetail/" + serviceDetail?.image
                      : "/serviceDetail/email-marketing.png"
                  }
                  alt="service detail"
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: serviceDetail?.description,
                  }}
                ></div>
              </div>
              <div className="servicesDetailColumn2">
                <div className="service-box">
                  <h3 className="heading">Services</h3>
                  <div>
                    {services?.length > 0 &&
                      services?.map((result, i) => (
                        <>
                          <a
                            key={"services" + i}
                            href={"/services/" + result?.slug}
                            className={
                              result?.slug === props.slug
                                ? "serviceCategory active"
                                : "serviceCategory"
                            }
                          >
                            {result?.title}
                          </a>
                        </>
                      ))}
                  </div>
                  <h3 className="heading">Contact Info</h3>
                  <label className={"label"}>Phone Number</label>
                  <p className="content">
                    <a href={`tel:+91 + ${appConstant.PHONE_NUMBER}`}>+91 {appConstant.PHONE_NUMBER}</a>
                  </p>
                  <label className={"label"}>Email</label>
                  <p className="content">
                    <a href="mailto:hi@idmvalley.com">hi@idmvalley.com</a>
                  </p>
                  <label className={"label"}>Address</label>
                  <p className="content">
                    <a
                      href="https://goo.gl/maps/vmdGqJBp61epkk4Q6"
                      rel="noreferrer"
                      target="_blank"
                    >
                      A7/15 Ground Floor, Sec 85 BPTP, Faridabad, HR, 121002
                    </a>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <div className="alternateBg">
        <Contact service={serviceDetail?.title} />
      </div>
      <hr />
      <Clients />
      <Footer />
      <Copyright />
    </>
  );
}

export default ServicesDetailPage;
