import './AboutPage.css';
import TopHeader from "../../shared/topHeader/TopHeader";
import Header from "../../shared/header/Header";
import Copyright from "../../shared/copyright/Copyright";
import Footer from "../../shared/footer/Footer";
import SinglePageBanner from "../../shared/singlePageBanner/SinglePageBanner";
import Clients from "../../shared/clients/Clients";
import Contact from '../../shared/contact/Contact';

function AboutPage() {
    return (
        <>
        <TopHeader/>
        <Header id='2' white='true'/>
        <SinglePageBanner page="About Us"/>
        <section className={'pages-content about-page'}>
            <div className='sub-title'>Our Steps</div>
            <h2 className="title">How It Works</h2>
            <div className='about-flex'>
                <div className='about-column'>
                    <div className='icon-box'>
                        <img className='designing' src='/img/illustration-purple.png' alt='icons' />
                    </div>
                    <h3 className='heading'>Designing</h3>
                    {/* <p className='content'>Suspendisse potenti. Pellentesque ornare mattis elit non fermentum. Mauris rhoncus efficitu.</p> */}
                </div>
                <div className='about-column'>
                    <div className='icon-box'>
                        <img className='development' src='/img/graphic-design-purple.png' alt='icons' />
                    </div>
                    <h3 className='heading'>Development</h3>
                    {/* <p className='content'>Suspendisse potenti. Pellentesque ornare mattis elit non fermentum. Mauris rhoncus efficitu.</p> */}
                </div>
                <div className='about-column'>
                    <div className='icon-box'>
                        <img className='launching' src='/img/startup-purple.png' alt='icons' />
                    </div>
                    <h3 className='heading'>Launching</h3>
                    {/* <p className='content'>Suspendisse potenti. Pellentesque ornare mattis elit non fermentum. Mauris rhoncus efficitu.</p> */}
                </div>
            </div>
        </section>
        <section className={'pages-content about-page alternateBg'}>
            <div className='about-flex-2'>
                <div className='about-column-2'>
                    <div className='about-img'>
                        <img className='static-img' src='/img/about-img/about-img-1.png' alt='about-img' />
                        <img className='moving-img img-1' src='/img/about-img/about-img-2.png' alt='about-img' />
                        <img className='moving-img img-2' src='/img/about-img/about-img-3.png' alt='about-img' />
                        <img className='moving-img img-3' src='/img/about-img/about-img-4.png' alt='about-img' />
                        <img className='moving-img img-4' src='/img/about-img/about-img-5.png' alt='about-img' />
                        <img className='moving-img img-5' src='/img/about-img/about-img-6.png' alt='about-img' />
                        <img className='moving-img img-6' src='/img/about-img/about-img-7.png' alt='about-img' />
                    </div>
                </div>
                <div className='about-column-2'>
                    <div className='sub-title'>About Us</div>
                    <h2 className="title">Empowering businesses to succeed in the digital age</h2>
                    <p className='content'>At IDM Valley, we understand the ever-evolving digital landscape and the impact it has on businesses. That's why we're dedicated to providing comprehensive digital solutions to help our clients achieve their goals.</p>
                    <p className='content'>Our team is composed of experts with a wealth of experience in various digital disciplines, including website development, search engine optimization, social media marketing, and email marketing. We work closely with our clients to understand their unique needs and develop customized strategies that drive results.</p>
                    <p className='content'>Our approach is data-driven, and we stay up-to-date with the latest industry trends and technologies to ensure that our clients stay ahead of the curve. From creating a website that accurately represents your brand to launching a successful digital marketing campaign, we're here to help you every step of the way.</p>
                    <ul>
                        <li>Specialize in website development, digital marketing, and mobile app development.</li>
                        <li>Team of experts with experience in the latest technologies and trends.</li>
                        <li>Tailor our services to meet the unique needs of our clients.</li>
                        <li>Data-driven approach to drive results and stay ahead of the competition.</li>
                        <li>Help businesses navigate the ever-changing digital landscape.</li>
                        <li>Goal is to provide innovative solutions that drive results.</li>
                        <li>Proud of our ability to understand our clients' unique needs.</li>
                        <li>Helping clients succeed is our top priority.</li>
                        <li>Can assist in taking business to the next level with our expertise.</li>
                    </ul>
                </div>
            </div>    
        </section>
        {/* contact */}
        <Contact/> 
        {/* Clients */}
        <hr/>
        <Clients/>
        {/* footer */}
        <Footer/>
        {/* copyright */}
        <Copyright/>
        </>
    );
  }
  
  export default AboutPage;

  






