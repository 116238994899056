import './Loader.css';

function Loader() {
    return (
        <>
        <div className='loader-container'>
            <span class="loader"></span>
        </div>
        </>
    );
  }
  
  export default Loader;
  


