import React, { useRef,useEffect,useState } from 'react';
import './Services.css';
import ServiceCard from '../serviceCard/ServiceCard';
import axios from 'axios';
import appConstant from '../constant/constant.json';
import Loader from '../loader/Loader';

function Services() {
    const [services, setServices] = React.useState([]);
    const [isLoader, setIsLoader] = useState(true);
    useEffect(() => {
            servicesData();
    }, []);
    const params = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
    };
        const servicesData = () => {
                axios.get(`${appConstant.API_URL}/services`, params)
                    .then(res => {
                        setIsLoader(false);
                            if (res.data.status === 200) {
                                setServices(res.data.data);
                            }
                    })
                    .catch(error => {
                        setIsLoader(true);
                    });
        }
    return (
        <section className={'services'}>
            {isLoader ? (
                      <Loader />
                      ) : (
                      <>
            {services?.length > 0 && services?.map((result, i) =>
                <>
            <ServiceCard key={'services' + i} bgColor={result?.service_color ? result?.service_color : 'bg-yellow'} img={result?.thumbnail ? result?.thumbnail : 'mobile-application.png'} title={result?.title ? result?.title : 'My title'} content={result?.short_description ? result?.short_description : 'Description goes here'} slug = {result?.slug ? result?.slug : '/'} />
                </>
            )}
             </>
              )}
        </section>
    );
}

export default Services;
