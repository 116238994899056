import React, {useEffect, useState} from "react";
import axios from "axios";
import appConstant from "../../shared/constant/constant.json";
import './BlogsPage.css';
import TopHeader from "../../shared/topHeader/TopHeader";
import Header from "../../shared/header/Header";
import Copyright from "../../shared/copyright/Copyright";
import Footer from "../../shared/footer/Footer";
import SinglePageBanner from "../../shared/singlePageBanner/SinglePageBanner";
import BlogsCard from '../../shared/blogsCard/BlogsCard';
import { Link } from 'react-router-dom';
import Loader from "../../shared/loader/Loader";

function BlogsPage() {
    const [isLoader, setIsLoader] = useState(true);
    const [blogs, setBlogs] = React.useState([]);
    useEffect(() => {
      blogsData();
    }, []);
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    const blogsData = () => {
      axios.get(`${appConstant.API_URL}/blogs`, params)
          .then(res => {
            setIsLoader(false);
            if (res.data.status === 200) {
              setBlogs(res.data.data);
            }
          })
          .catch(error => {
            setIsLoader(true);
          });
    }
    return (
        <>
        <TopHeader/>
        <Header id='5' white='true'/>
        <SinglePageBanner page="Blogs"/>
        <section className={'pages-content'}>
        <div className='blogsPage'>
          {blogs?.length > 0 ? 
                  <>
              <div className='blogsColumn1'>
              {isLoader ? (
                      <Loader />
                      ) : (
                      <>
                  <div className='card-container'>                    
                      {blogs?.map((result, i) =>
                          <BlogsCard data={result} />                                           
                      )}                 
                  </div>
                  </>
              )}
              </div>
              <div className='blogsColumn2'>
                  <div className='recent-box'>
                      <h3 className='heading'>Recent Blogs</h3>                       
                      {blogs?.length > 0 && blogs?.map((result, i) =>
                            <Link  key={'blogs' + i} to={'/blogs/' + result?.slug} className='recent-blogs'>
                            <div className='recent-column1'>
                                <img className="blog-img" src={`${appConstant.IMAGE_URL}/blogs/` + result?.image} alt={result?.title}/>
                            </div>
                            <div className='recent-column2'>
                                <h6 className='blog-title'>{result?.title}</h6>
                                <div className='blog-content' dangerouslySetInnerHTML={{__html: result?.description}}></div>
                            </div>
                        </Link>                                           
                      )}
                        
                  </div>        
              </div>
              </>
            : 
            <><img className="comming-soon" src='/img/comingsoon.png' alt='coming soon' /></>
          }
        </div>
        </section>
        {/* footer */}
        <Footer/>
        {/* copyright */}
        <Copyright/>
        </>
    );
  }
  
  export default BlogsPage;