import { Link } from "react-router-dom";
import './BlogsCard.css';
import appConstant from "../constant/constant.json";

function BlogsCard(props) {
    const data = props.data;
    return (
        <Link to={'/blogs/' + data?.slug} className='blog-card'>
            <img className="blog-img" src={`${appConstant.IMAGE_URL}/blogs/` + data?.image} alt={data?.title}/>
            <div className="blog-body">
                <h6 className='blog-title'>{data?.title}</h6>
                <div className='blog-content' dangerouslySetInnerHTML={{__html: data?.description}}></div>
            </div>
        </Link>
    );
  }
  
  export default BlogsCard;