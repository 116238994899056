import React, {useEffect, useState} from 'react'
import './ContactForm.css';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import appConstant from "../constant/constant.json";

function ContactForm(props) {
    const [services, setServices] = useState([]);
    const [formMessage, setFormMessage] = useState('');
    const [captchaValue, setCaptchavalue] = useState('');
    function onChange(value) {
        setCaptchavalue(value);
    }
    useEffect(() => {
        servicesData();
        if(props?.myService){
            const insertService = {
                fname : "",
                email : "",
                number : "",
                service : props?.myService
            }
            setInputValues(insertService);
        }
    }, []);
    const params = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
    };
    const servicesData = () => {
        axios.get(`${appConstant.API_URL}/services`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setServices(res.data.data);
                }
            })
            .catch(error => {
            });
    }
    const [inputValues, setInputValues] = useState({
        fname : "",
        email : "",
        number : "",
        service : ""
    });
    const handelChange = (e) => {
        const {name, value} = e.target;
        setInputValues({...inputValues, [name]: value});
    }
    const [validation, setValidation] = useState({
        fname : "",
        email : "",
        number : "",
        captcha: ""
    });
    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));

        //fullname validation
        const alphabetCond = /^[a-zA-Z ]*$/;
        if (!inputValues.fname) {
        errors.fname = "Name is required";
        } else if (!inputValues.fname.match(alphabetCond)) {
        errors.fname = "Please enter valid Name";
        } else {
        errors.fname = "";
        }
        //number validation
        const numberCond = /^[0-9\b]+$/;
        if (!inputValues.number) {
          errors.number = "Number is required";
        } else if (!inputValues.number.match(numberCond)) {
          errors.number = "Please enter valid number";
        } else if (inputValues.number.length < 9) {
          errors.number = "Please enter valid phone number.";
        } else {
          errors.number = "";
        }
        // email validation
        const emailCond = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!inputValues.email) {
          errors.email = "Email is required";
        } else if (!inputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }
        if(captchaValue === ''){
            errors.captcha = "Please enter captcha";
        } else {
            errors.captcha = "";
        }
        setValidation(errors);
        if(errors.fname === '' && errors.number === '' && errors.email === '' && errors.captcha === ''){
          return true;
        }else{
          return false;
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const isSuccess = checkValidation();
        if(isSuccess) {
            const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            };
            const data = new FormData();
            data.append('full_name', inputValues.fname);
            data.append('phone_number', inputValues.number);
            data.append('email', inputValues.email);
            data.append('message', inputValues.message);
            data.append('service', inputValues.service);
            axios.post(`${appConstant.API_URL}/contact`, data, params)
                .then(res => {
                    if (res?.data?.status === 200) {
                        setFormMessage(res?.data?.message);
                        const inputData = {
                            fname : "",
                            email : "",
                            number : "",
                            service : ""
                        }
                        setInputValues(inputData);
                    }else{
                        setFormMessage(res?.data?.message);
                    }
                })
                .catch(error => {
                });
        }
    }
    return (
      <div className='contactForm'>
          {formMessage && <p className='formErrors'>{formMessage}</p>}
        <form onSubmit={handleSubmit}>
            <div className={'inputBox'}>
                <label className={'label'}>Full Name</label>
                <input type="text" className={'input'} placeholder='Enter Name' name='fname' value={inputValues.fname} onChange={handelChange}/>
                {validation.fname && <p className='formErrors'>{validation.fname}</p>}
            </div>
            <div className={'inputBox'}>
                <label className={'label'}>Email</label>
                <input type="text" className={'input'} placeholder='Enter Email' name='email' value={inputValues.email} onChange={handelChange}/>
                {validation.email && <p className='formErrors'>{validation.email}</p>}
            </div>
            <div className={'inputBox'}>
                <label className={'label'}>Phone Number</label>
                <input type="text" className={'input'} placeholder='Enter Phone Number' name='number' value={inputValues.number} onChange={handelChange}/>
                {validation.number && <p className='formErrors'>{validation.number}</p>}
            </div>
            <div className={'inputBox'}>
                <label className={'label'}>Select Service</label>
                <select className={'input'} name='service' value={inputValues.service} onChange={handelChange}>
                    {services?.length > 0 && services?.map((result, i) =>
                        <>
                            <option key={'services' + i} value={result?.title} selected={(result?.title === props.service) ? true : false}>{result?.title}</option>
                        </>
                    )}

                </select>
            </div>
            <div className={'inputBox'}>
                <label className={'label'}>Message</label>
                <textarea className={'textarea'} placeholder='Message' name='message' value={inputValues.message} onChange={handelChange}></textarea>
            </div>
            <ReCAPTCHA
              sitekey="6LdQM0ofAAAAAG_C-otQT2qOYMg4qiLS3obTmdd8"
              onChange={onChange}
            />
            {validation.captcha && <p className='formErrors'>{validation.captcha}</p>}
            <button type='submit' className='connect-btn'><div className="button"><span>&#10170;</span></div>Submit</button>
        </form>
      </div>
    );
  }

  export default ContactForm;
