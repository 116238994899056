import './HomePage.css';
import TopHeader from "../../shared/topHeader/TopHeader";
import Header from "../../shared/header/Header";
import Copyright from "../../shared/copyright/Copyright";
import Contact from '../../shared/contact/Contact';
import Footer from "../../shared/footer/Footer";
import Clients from "../../shared/clients/Clients";
import PortfolioCard from '../../shared/portfolioCard/PortfolioCard';
import Services from '../../shared/services/Services';
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import React, {useEffect} from "react";
import axios from "axios";
import appConstant from "../../shared/constant/constant.json";
import Loader from '../../shared/loader/Loader';

function HomePage() {
  const [testimonials, setTestimonials] = React.useState([]);
  const [portfolio, setPortfolio] = React.useState([]);
  const [isLoader, setIsLoader] = React.useState(true);
  const [isPortfolioLoader, setIsPortfolioLoader] = React.useState(true);
    useEffect(() => {
      portfolioData();
      testimonialsData();
    }, []);
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    const portfolioData = () => {
      axios.get(`${appConstant.API_URL}/home_portfolio`, params)
          .then(res => {
            setIsPortfolioLoader(false);
            if (res.data.status === 200) {
              setPortfolio(res.data.data);
            }
          })
          .catch(error => {
            setIsPortfolioLoader(true);
          });
    }
  const testimonialsData = () => {
    axios.get(`${appConstant.API_URL}/testimonials`, params)
        .then(res => {
          setIsLoader(false);
          if (res.data.status === 200) {
            setTestimonials(res.data.data);
          }
        })
        .catch(error => {
          setIsLoader(true);
        });
  }
  return (
    <>
        <TopHeader/>
        <Header id='1'/>
        <div className="home">
          {/* hero */}
          <div className="hero-section">
            <div className='hero-column'>
              <h6 className='tagline'>We create ideas</h6>
              <h1 className="heading ">Elevating your online presence, one <span className="text-primary">pixel </span> at a time.</h1>
              <p className="content">Unlocking the power of digital to drive your business forward.</p>
              <Link className='connect-btn' to='/contact'><div className="button"><span>&#10170;</span></div> Let's Connect</Link>
            </div>
            <div className='hero-column'>
              <div className='hero-img'>
                <img className='static-img' src='/img/hero-img/hero-img-base.png' alt='hero-img' />
                <img className='moving-img img-1' src='/img/hero-img/hero-img-1.png' alt='hero-img' />
                <img className='moving-img img-2' src='/img/hero-img/hero-img-2.png' alt='hero-img' />
                <img className='moving-img img-3' src='/img/hero-img/hero-img-3.png' alt='hero-img' />
                <img className='moving-img img-4' src='/img/hero-img/hero-img-3.png' alt='hero-img' />
                <img className='moving-img img-5' src='/img/hero-img/hero-img-3.png' alt='hero-img' />
                <img className='moving-img img-6' src='/img/hero-img/hero-img-4.png' alt='hero-img' />
                <img className='moving-img img-7' src='/img/hero-img/hero-img-5.png' alt='hero-img' />
                <img className='moving-img img-8' src='/img/hero-img/hero-img-6.png' alt='hero-img' />
                <img className='moving-img img-9' src='/img/hero-img/hero-img-7.png' alt='hero-img' />
                <img className='moving-img img-10' src='/img/hero-img/hero-img-8.png' alt='hero-img' />
                <img className='moving-img img-11' src='/img/hero-img/hero-img-9.png' alt='hero-img' />
                <img className='moving-img img-12' src='/img/hero-img/hero-img-10.png' alt='hero-img' />
                <img className='moving-img img-13' src='/img/hero-img/hero-img-11.png' alt='hero-img' />
                <img className='moving-img img-14' src='/img/hero-img/hero-img-12.png' alt='hero-img' />
                <img className='moving-img img-15' src='/img/hero-img/hero-img-13.png' alt='hero-img' />
                <img className='moving-img img-16' src='/img/hero-img/hero-img-14.png' alt='hero-img' />
                <img className='moving-img img-17' src='/img/hero-img/hero-img-15.png' alt='hero-img' />
                <img className='moving-img img-18' src='/img/hero-img/hero-img-16.png' alt='hero-img' />
              </div>
            </div>
          </div>
          {/* feature */}
          <div className='features-section'>
            <div className='features-columns1'>
              <div className='feature-card-box'>
                <div className='feature-card'>
                  <div className='card-box card-1'>
                    <div className="icon"><img src='/img/seo.png' alt='icons' /></div>
                    <h6 className='mini-title'>Influencer Marketing</h6>
                    <p className='mini-content'>Amplify your brand's reach with the power of influencer marketing</p>
                  </div>
                </div>
                <div className='feature-card'>
                  <div className='card-box card-2'>
                    <div className="icon"><img src='/img/analysis.png' alt='icons' /></div>
                    <h6 className='mini-title'>Mobile Applications</h6>
                    <p className='mini-content'>Empowering you on the go with our cutting-edge mobile solutions</p>
                  </div>
                </div>
                <div className='feature-card'>
                  <div className='card-box card-3'>
                    <div className="icon"><img src='/img/marketing.png' alt='icons' /></div>
                    <h6 className='mini-title'>Digital Marketing</h6>
                    <p className='mini-content'>Leading the way in innovative digital marketing solutions.</p>
                  </div>
                </div>
                <div className='feature-card'>
                  <div className='card-box card-4'>
                    <div className="icon"><img src='/img/monitor.png' alt='icons' /></div>
                    <h6 className='mini-title'>Website Development</h6>
                    <p className='mini-content'>Building your online presence, brick by brick</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='features-columns2'>
              <div className='sub-title'>News</div>
              <h2 className="title">Do you Know?</h2>
              <p className='content'>1. The global digital marketing industry is projected to reach $335 billion in 2020, and is expected to grow at a CAGR of 10.5% during the forecast period 2021-2028.</p>
              <p className='content'>2. Social media advertising is expected to generate the most revenue among all digital advertising formats, reaching $89 billion in 2020.</p>
              <p className='content'>3. Search engine optimization (SEO) is expected to be the most in-demand digital marketing service, with 38% of businesses planning to invest in it.</p>
              <p className='content'>4. The use of mobile marketing is expected to increase in the coming years, with over 200 billion mobile app downloads projected for 2022.</p>
              <p className='content'>5. Email marketing is expected to remain an effective strategy, with an estimated ROI of $38 for every $1 spent.</p>
              <p className='content'>6. Influencer marketing is projected to be a $10 billion industry by 2020</p>
              <p className='content'>7. Video marketing is expected to dominate digital marketing in coming years, with 85% of internet users in the US watching video content on their devices.</p>
            </div>

            <div className="features-animation">
              <div className="shape-img1">
                <img src="/img/8.png" alt="shape"/>
              </div>
              <div className="shape-img2">
                <img src="/img/5.png" alt="shape"/>
              </div>
            </div>
          </div>
          {/* about */}
          <div className='about-section alternateBg'>
            <div className='about-columns1'>
              <div className='sub-title'>About Us</div>
              <h2 className="title">How We Can Help You Achieve Your Business Goals</h2>
              <p className='content'>We understand the importance of having a solid digital presence for any business. We are dedicated to helping businesses of all sizes achieve their goals by providing a wide range of services to enhance their online presence.</p>
              <p className='content'>Our team of experts is well-versed in the latest digital marketing trends and technologies, and can help you create a custom strategy to suit your specific needs.</p>
              <Link className='connect-btn' to='/about'><div className="button"><span>&#10170;</span></div> Read More</Link>
            </div>
            <div className='about-columns2'>
                <div className='about-img'>
                  <img className='static-img' src='/img/about-img/about-img-1.png' alt='about-img' />
                  <img className='moving-img img-1' src='/img/about-img/about-img-2.png' alt='about-img' />
                  <img className='moving-img img-2' src='/img/about-img/about-img-3.png' alt='about-img' />
                  <img className='moving-img img-3' src='/img/about-img/about-img-4.png' alt='about-img' />
                  <img className='moving-img img-4' src='/img/about-img/about-img-5.png' alt='about-img' />
                  <img className='moving-img img-5' src='/img/about-img/about-img-6.png' alt='about-img' />
                  <img className='moving-img img-6' src='/img/about-img/about-img-7.png' alt='about-img' />
                </div>
            </div>
          </div>
          {/* projects */}
          <div className='services-section'>
            <div className='sub-title'>Services</div>
            <h2 className="title">Provide Awesome Service To You</h2>
            <Services />
            {/* <Link className='connect-btn' to='/services'><div className="button"><span>&#10170;</span></div> View All</Link> */}
          </div>
          {/* happy client */}
          {/* <div className='happy-client-section'>
            <div className='happy-client-column'>
              <img className='icons' src='/img/happy.png'  alt='happy'/>
              <h4 className='head'>Satisfied Clients</h4>
              <h2 className='count'>50+</h2>
            </div>
            <div className='happy-client-column'>
              <img className='icons' src='/img/flowchart.png'  alt='happy'/>
              <h4 className='head'>Project Completed</h4>
              <h2 className='count'>30+</h2>
            </div>
            <div className='happy-client-column'>
              <img className='icons' src='/img/shuttle.png'  alt='happy'/>
              <h4 className='head'>Project Lunched</h4>
              <h2 className='count'>30+</h2>
            </div>
            <div className='happy-client-column'>
              <img className='icons' src='/img/confetti.png'  alt='happy'/>
              <h4 className='head'>Years Completed</h4>
              <h2 className='count'>3+</h2>
            </div>
          </div> */}
           {/* testimonials */}
          <div className='testimonials'>
          {isLoader ? (
                      <Loader />
                      ) : (
                      <>
           <Carousel autoPlay='true' showIndicators='false' showArrows='false' infiniteLoop='true' showThumbs='true'>
             {testimonials?.length > 0 && testimonials?.map((result, i) =>
                 <div className='testimonial-groups' key={'testimonial' + i}>
                   <h4 className='person-name'>{result?.author_name}</h4>
                   <h6 className='designation'>{result?.designation}</h6>
                   <p className='content' dangerouslySetInnerHTML={{__html: result?.description}}></p>
                   <img className='avtar' src={result?.image ? '/clients/' + result?.image : '/clients/avshack.png'} alt={result?.author_name} />
                 </div>
             )}
            </Carousel>
            </>
              )}
          </div>
          {/* projects */}
          <div className='portfolio-section'>
            <div className='sub-title'>Projects</div>
            <h2 className="title">Take A Look At Our Latest Portfolio</h2>
            {isPortfolioLoader ? (
                  <Loader />
                  ) : (
                  <>
                  <div className='portfolio-flex'>
                    {portfolio?.length > 0 && portfolio?.map((result, i) =>
                        <PortfolioCard data={result} />                                           
                    )}
                  </div>
                  </>
              )}
            <Link className='connect-btn' to='/portfolio'><div className="button"><span>&#10170;</span></div> View All</Link>
          </div>
          {/* contact */}
          <div className='alternateBg'>
            <Contact/>
          </div>
          {/* client */}
          <Clients/>
        </div>

        {/* footer */}
        <Footer/>
        {/* copyright */}
        <Copyright/>
    </>
  );
}

export default HomePage;
