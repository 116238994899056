import './ServicesPage.css';
import TopHeader from "../../shared/topHeader/TopHeader";
import Header from "../../shared/header/Header";
import Copyright from "../../shared/copyright/Copyright";
import Footer from "../../shared/footer/Footer";
import SinglePageBanner from "../../shared/singlePageBanner/SinglePageBanner";
import Services from '../../shared/services/Services';
import Clients from "../../shared/clients/Clients";

function ServicesPage() {
    return (
        <>
        <TopHeader/>
        <Header id='3' white='true'/>
        <SinglePageBanner page="Our Services"/>
        <section className={'pages-content'}>
            <Services />
        </section>
        <hr/>
        <Clients/>
        <Footer/>
        <Copyright/>
        </>
    );
  }
  
  export default ServicesPage;