import './PortfolioPage.css';
import TopHeader from "../../shared/topHeader/TopHeader";
import Header from "../../shared/header/Header";
import Copyright from "../../shared/copyright/Copyright";
import Footer from "../../shared/footer/Footer";
import SinglePageBanner from "../../shared/singlePageBanner/SinglePageBanner";
import Portfolio from '../../shared/portfolio/Portfolio';
import Clients from "../../shared/clients/Clients";

function PortfolioPage() {
    return (
        <>
        <TopHeader/>
        <Header id='4' white='true'/>
        <SinglePageBanner page="Recent Work"/>
        <section className={'pages-content'}>
            <Portfolio/>
        </section>
        <hr/>
        <Clients/>
        <Footer/>
        <Copyright/>
        </>
    );
  }
  
  export default PortfolioPage;