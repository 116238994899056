import './TopHeader.css';
import ScrollToTop from '../ScrollToTop';
import appConstant from "../../shared/constant/constant.json";

function TopHeader() {
    return (
        <>
        <ScrollToTop/>
        <section className={'topHeader'}>
            <div className={'contactBox'}>
            <img className={'icons'} src="/img/email.png" alt="email"/> <a className={'links'} href="mailto:hi@idmvalley.com">hi@idmvalley.com</a>
            <img className={'icons'} src="/img/phone.png" alt="call"/> <a className={'links'} href={`tel:+91 + ${appConstant.PHONE_NUMBER}`}>+91 {appConstant.PHONE_NUMBER}</a>
            </div>
            <div className={'socialLinks'}>
            <a rel="noreferrer" href="https://www.facebook.com/idmvalley/" target={'_blank'}><img className={'icons'} src="/img/facebook-white.png" alt="facebook"/></a>
            <a rel="noreferrer" href="https://twitter.com/idmvalley" target={'_blank'}><img className={'icons'} src="/img/twitter-white.png" alt="twitter"/></a>
            <a rel="noreferrer" href="https://www.instagram.com/idmvalley/" target={'_blank'}><img className={'icons'} src="/img/instagram-white.png" alt="instagram"/></a>
            <a rel="noreferrer" href="https://www.linkedin.com/in/idm-valley-870628207/?originalSubdomain=in" target={'_blank'}><img className={'icons'} src="/img/linkedin-white.png" alt="linkedin"/></a>
            <a rel="noreferrer" href="https://api.whatsapp.com/send?phone=919891054016&text=Hello" target={'_blank'}><img className={'icons'} src="/img/whatsapp-white.png" alt="whatsapp"/></a>
            </div>
        </section>
        </>
    );
  }
  
  export default TopHeader;