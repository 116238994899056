import React, { useEffect } from "react";
import axios from "axios";
import appConstant from "../../shared/constant/constant.json";
import "./FaqPage.css";
import TopHeader from "../../shared/topHeader/TopHeader";
import Header from "../../shared/header/Header";
import Copyright from "../../shared/copyright/Copyright";
import Footer from "../../shared/footer/Footer";
import SinglePageBanner from "../../shared/singlePageBanner/SinglePageBanner";
import Clients from "../../shared/clients/Clients";
import Loader from "../../shared/loader/Loader";

function FaqPage() {
  const [faqs, setFaqs] = React.useState([]);
  const [isLoader, setIsLoader] = React.useState(true);
  useEffect(() => {
    faqsData();
  }, []);
  const params = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const faqsData = () => {
    axios
      .get(`${appConstant.API_URL}/faqs`, params)
      .then((res) => {
        setIsLoader(false);
        if (res.data.status === 200) {
          setFaqs(res.data.data);
        }
      })
      .catch((error) => { setIsLoader(true);});
  };
  return (
    <>
      <TopHeader />
      <Header white="true" />
      <SinglePageBanner page="Frequently Asked Questions" />
      <section className={"pages-content"}>
        <div className="terms-section">
          <div className="sub-title">Frequently Asked Questions</div>
          {isLoader ? (
            <Loader />
          ) : (
            <>
              {faqs?.length > 0 &&
                faqs?.map((result, i) => (
                  <div key={"faqs" + i}>
                    <h4 className="mini-title">{result?.ques}</h4>
                    <div
                      dangerouslySetInnerHTML={{ __html: result?.data }}
                    ></div>
                  </div>
                ))}
            </>
          )}
          <br />
          <br />
        </div>
        <div className="contact-flex">
          <div className="contact-box">
            <div className="icon">
              <img className="phone" alt="icon" src="/img/phone-black.png" />
            </div>
            <div className="contact-content">
              <h4>Phone</h4>
              <a href={`tel:+91 + ${appConstant.PHONE_NUMBER}`} className='links'> +91 {appConstant.PHONE_NUMBER}</a>
            </div>
          </div>
          <div className="contact-box">
            <div className="icon">
              <img
                alt="icon"
                className="whatsapp"
                src="/img/whatsapp-black.png"
              />
            </div>
            <div className="contact-content">
              <h4>Whatsapp</h4>
              <a
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=919891054016&amp;text=Hello"
                target="_blank"
                className="links"
              >
                +91 {appConstant.PHONE_NUMBER}
              </a>
            </div>
          </div>
          <div className="contact-box">
            <div className="icon">
              <img alt="icon" className="email" src="/img/email-black.png" />
            </div>
            <div className="contact-content">
              <h4>E-mail</h4>
              <a href="mailto:hi@idmvalley.com" className="links">
                hi@idmvalley.com
              </a>
            </div>
          </div>
          <div className="contact-box">
            <div className="icon">
              <img alt="icon" className="home" src="/img/home-black.png" />
            </div>
            <div className="contact-content">
              <h4>Location</h4>
              <a
                href="https://goo.gl/maps/vmdGqJBp61epkk4Q6"
                rel="noreferrer"
                target="_blank"
                className="links"
              >
                A7/15 Ground Floor, Sec 85 BPTP, Faridabad, HR, 121002
              </a>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <Clients />
      <Footer />
      <Copyright />
    </>
  );
}

export default FaqPage;
