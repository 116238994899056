import { useState } from 'react'
import '../portfolio/Portfolio.css'
import appConstant from "../constant/constant.json";
function PortfolioCard(props) {
    const data = props.data;
    const [modal, setShowModal] = useState(false);
    const showModal = () => {
        setShowModal(true);
    }
    const closeModal = () => {
        setShowModal(false);
    }

    return (
        <>
        <div className={'galleryItem'}>
            <img className={'galleryImage'} src={`${appConstant.IMAGE_URL}/portfolio/` + data?.image} alt={data?.title} />
            <div className={'galleryButtons'}>
                <div></div>                
                <button className={'galleryIcons'} onClick={showModal} ><img src='/img/zoom.png' alt='link' /></button>
                <h5 className={'galleryTitle'}>{data?.title}</h5>                        
            </div>
        </div>
        {modal ? 
            <div className='modal'>
                <div className='modal-body'>
                    <button className='close-btn' onClick={closeModal}>&times;</button>
                    <div className='modal-content'>
                        <img className={'modalImage'} src={`${appConstant.IMAGE_URL}/portfolio/` + data?.image} alt={data?.title} />
                    </div>
                </div>
            </div>
            : ''
        }
        </>
    );
  }
  
  export default PortfolioCard;