import React, { useState, useEffect } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';

function Header(props) {
    const [headerActiveClass, setHeaderActiveClass] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 30) {
                setHeaderActiveClass(true);
            } else {
                setHeaderActiveClass(false);
            }
        });
    }, []);
    return (
        <header className={'header ' + (headerActiveClass ? 'active ' : '') + (props.white ? 'white' : '')}>
            <div className={'logo'}>
                <Link to='/'>
                    <img src="/img/logo.png" alt='logo' />
                    {/* <h1 className='heading'>IDMValley</h1> */}
                    {/* <span>We Build What You Think</span> */}
                </Link>
            </div>
            <a rel="noreferrer" href="https://api.whatsapp.com/send?phone=919891054016&text=Hello" target={'_blank'} className={'talk-btn talk-2'}>Let's Talk</a>
            <input className={'menuToggle'} id="menu-toggle" type="checkbox" />
            <label className={'menuButtonContainer'} htmlFor="menu-toggle">
                <div className={'menuButton'}></div>
            </label>
            <nav className={'navbar'}>
                <Link className={'navlinks ' + (props.id === "1" ? 'navActive' : '')} to='/'>Home</Link>
                <Link className={'navlinks ' + (props.id === "2" ? 'navActive' : '')} to='/about'>About Us</Link>
                <Link className={'navlinks ' + (props.id === "3" ? 'navActive' : '')} to='/services'>Services</Link>
                <Link className={'navlinks ' + (props.id === "4" ? 'navActive' : '')} to='/portfolio'>Portfolio</Link>
                <Link className={'navlinks ' + (props.id === "5" ? 'navActive' : '')} to='/blogs'>Blogs</Link>
                <Link className={'navlinks ' + (props.id === "6" ? 'navActive' : '')} to='/contact'>Contact Us</Link>
                <a rel="noreferrer" href="https://api.whatsapp.com/send?phone=919891054016&text=Hello" target={'_blank'} className={'talk-btn talk-1'}>Let's Talk</a>
            </nav>      
        </header>
    );
  }
  
  export default Header;
  


